<template>
  <div class="CBD">
    <!-- CBD -->
    <div class="cost-break-down">
      <div class="title d-flex">
        <!-- <router-link :to="{ name: 'BOM' }"> -->
        <a @click="goBack">
          <i class="fa-solid fa-angle-left" style="margin-right: 10px" />
        </a>
        <!-- </router-link> -->

        <h1>CBD (Cost Break Down)</h1>
        <img
          :src="require('images/ico_xlsx.png')"
          class="align-self-center ml-1 pointer"
          style="width: auto; height: 24px"
          v-if="!editMode"
          @click="downloadExcel" />

        <v-btn class="ml-2" color="secondary" v-if="editMode && !createMode" :elevation="0" @click="discardClicked">
          DISCARD
        </v-btn>
        <v-spacer />
        <v-btn v-if="!editMode" :disabled="cbd.isClosed" color="secondary" :elevation="0" @click="changeEditMode(true)">
          MODIFY
        </v-btn>
        <v-btn v-else color="secondary" :elevation="0" @click="save" :loading="saveLoading">SAVE</v-btn>
      </div>

      <div class="info-card-list d-flex">
        <!-- year/season -->
        <div class="info-card">
          <p>YEAR / SEASON</p>
          <p>{{ `${cbd.cbdBomSelectDto.bomYear} / ${cbd.cbdBomSelectDto.season}` }}</p>
        </div>
        <!-- buyer brand -->
        <div class="info-card">
          <p>BUYER BRAND</p>
          <p>{{ `${cbd.cbdBomSelectDto.offerBrand}` }}</p>
        </div>
        <!-- gender -->
        <div class="info-card">
          <p>GENDER</p>
          <p>{{ `${cbd.cbdBomSelectDto.gender}` }}</p>
        </div>
        <!-- garment type -->
        <div class="info-card">
          <p>GARMENT TYPE</p>
          <p>{{ `${cbd.cbdBomSelectDto.garmentType}` }}</p>
        </div>
        <!-- fabric type -->
        <div class="info-card">
          <p>FABRIC TYPE</p>
          <p>{{ `${cbd.cbdBomSelectDto.fabricType}` }}</p>
        </div>
        <!-- design no -->
        <div class="info-card">
          <p>DESIGN #</p>
          <p>{{ `${cbd.cbdBomSelectDto.designNo}` }}</p>
        </div>
        <!-- style no -->
        <div class="info-card">
          <p>STYLE #</p>
          <div class="d-flex font-weight-light">
            <p
              :class="{ underline: editMode, pointer: editMode }"
              @click="
                () => {
                  if (editMode) selectStyleNumberShow = true;
                }
              ">
              {{ styleNoText }}
            </p>
            <v-btn
              v-if="editMode && !hasValue(styleNoText)"
              outlined
              small
              color="secondary"
              @click="selectStyleNumberShow = true"
              :style="styleNoText.trim() !== '' ? 'margin-left:6px' : ''">
              SELECT
            </v-btn>
          </div>
        </div>
        <!-- bom title -->
        <div class="info-card">
          <p>BOM TITLE</p>
          <p>{{ `${cbd.cbdBomSelectDto.coverName}` }}</p>
        </div>
        <!-- status -->
        <div class="info-card">
          <p>STATUS</p>
          <p v-if="editMode">
            {{ `${cbd.isClosed ? "CLOSED" : "OPEN"}` }}
          </p>
          <input-switch
            v-else
            :isActive="cbd.isClosed"
            activeColor="#EB5757"
            inactiveColor="#3A989E"
            :label="cbd.isClosed ? 'CLOSED' : 'OPEN'"
            @click="switchClick(cbd)" />
        </div>
      </div>

      <div class="custom-table-list d-flex">
        <!-- left -->
        <div class="info-table-list">
          <div class="info-table">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th>DEPARTMENT</th>
                    <th :class="{ essential: editMode }">COO</th>
                    <th>PCD</th>
                    <th>SHIP DATE</th>
                    <th :class="{ essential: editMode }">DESTINATION</th>
                    <th>EXHANGE RATE</th>
                    <th :class="{ essential: editMode }">CBD TITLE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- DEPARTMENT -->
                    <td>
                      <span v-if="!editMode"> {{ cbd.department }} </span>
                      <v-text-field
                        v-else
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        v-model="cbd.department" />
                    </td>
                    <!-- COO -->
                    <td :class="{ required: !hasValue(cbd.coo) }">
                      <span v-if="!editMode"> {{ cbd.coo }} </span>
                      <v-text-field
                        v-else
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        v-model="cbd.coo">
                        <template v-slot:label>
                          <span class="font-error">Required</span>
                        </template>
                      </v-text-field>
                    </td>
                    <!-- PCD -->
                    <td>
                      <span v-if="!editMode"> {{ cbd.pcd }} </span>
                      <input-date v-else :open.sync="cbd.pcdOpen" :date.sync="cbd.pcd" :required="false" />
                    </td>
                    <!-- SHIP DATE -->
                    <td>
                      <span v-if="!editMode"> {{ cbd.shippingDate }} </span>
                      <input-date
                        v-else
                        :open.sync="cbd.shippingDateOpen"
                        :date.sync="cbd.shippingDate"
                        :required="false" />
                    </td>
                    <!-- DESTINATION -->
                    <td :class="{ required: !hasValue(cbd.destination) }">
                      <span v-if="!editMode"> {{ cbd.destination }} </span>
                      <v-text-field
                        v-else
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        v-model="cbd.destination">
                        <template v-slot:label>
                          <span class="font-error">Required</span>
                        </template>
                      </v-text-field>
                    </td>
                    <!-- EXHANGE RATE -->
                    <td>
                      <span v-if="!editMode"> {{ numberWithComma(cbd.exchangeRate) }} </span>
                      <v-text-field
                        v-else
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        type="number"
                        v-model.number="cbd.exchangeRate" />
                    </td>
                    <!-- CBD TITLE -->
                    <td :class="{ required: !hasValue(cbd.cbdTitle) }">
                      <span v-if="!editMode"> {{ cbd.cbdTitle }} </span>
                      <v-text-field
                        v-else
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        v-model="cbd.cbdTitle">
                        <template v-slot:label>
                          <span class="font-error">Required</span>
                        </template>
                      </v-text-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="info-table">
            <div class="custom-table">
              <table>
                <thead>
                  <tr>
                    <th :class="{ essential: editMode }">ORDER QTY</th>
                    <th :class="{ essential: editMode }">FINAL PRICE</th>
                    <th :class="{ essential: editMode }">PROFIT</th>
                    <th>AMOUNT</th>
                    <th>CREATED BY</th>
                    <th>CREATED DATE</th>
                    <th>ORDER STATUS MEMO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- ORDER QTY -->
                    <td :class="{ required: !hasValue(cbd.orderQty) }">
                      <span v-if="!editMode">{{ numberWithComma(cbd.orderQty) }}</span>
                      <v-text-field
                        v-else
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        type="number"
                        v-model.number="cbd.orderQty"
                        @keyup="setAmount"
                        @change="setAmount">
                        <template v-slot:label>
                          <span class="font-error">Required</span>
                        </template>
                      </v-text-field>
                    </td>
                    <!-- FINAL PRICE -->
                    <td :class="{ required: !hasValue(cbd.finalPriceUom) || !hasValue(cbd.finalPrice) }">
                      <span v-if="!editMode">{{ cbd.finalPriceUom }} {{ numberWithComma(cbd.finalPrice) }}</span>
                      <div v-else class="d-flex align-center">
                        <div style="width: 100px">
                          <v-autocomplete
                            dense
                            solo
                            hide-details
                            flat
                            outlined
                            :items="$store.state.code.common.currency"
                            item-value="itemCd"
                            item-text="itemCd"
                            v-model="cbd.finalPriceUom">
                            <template v-slot:label>
                              <span class="font-error">Required</span>
                            </template>
                            <template v-slot:append>
                              <v-icon> mdi-chevron-down </v-icon>
                            </template>
                          </v-autocomplete>
                        </div>
                        <template v-if="useFinalPrice === true">
                          <v-text-field
                            solo
                            flat
                            dense
                            outlined
                            hide-details
                            background-color="white"
                            type="number"
                            v-model.number="cbd.finalPrice"
                            @keyup="changeFinalPrice(cbd.finalPrice)"
                            @change="changeFinalPrice(cbd.finalPrice)">
                            <template v-slot:label>
                              <span class="font-error">Required</span>
                            </template>
                          </v-text-field>
                        </template>
                        <template v-else>
                          <span style="margin-left: 8px">
                            {{ cbd.finalPrice }}
                          </span>
                          <v-spacer></v-spacer>
                          <v-btn text icon x-small @click="useFinalPrice = true">
                            <v-icon x-small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                      </div>
                    </td>
                    <!-- PROFIT -->
                    <td :class="{ required: !hasValue(cbd.profit) }">
                      <span v-if="!editMode">{{ fixedNumber(cbd.profit, $mathFix, true) }}%</span>
                      <div v-else class="d-flex align-center">
                        <template v-if="useFinalPrice === false">
                          <v-text-field
                            solo
                            flat
                            dense
                            outlined
                            hide-details
                            background-color="white"
                            type="number"
                            v-model.number="cbd.profit">
                            <template v-slot:label>
                              <span class="font-error">Required</span>
                            </template>
                          </v-text-field>
                          %
                        </template>
                        <template v-else>
                          <span style="width: 100%; text-align: center">
                            {{ fixedNumber(cbd.profit, $mathFix, true) }}%
                          </span>
                          <v-btn text icon x-small @click="useFinalPrice = false">
                            <v-icon x-small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                      </div>
                    </td>
                    <!-- AMOUNT -->
                    <td>
                      <span>
                        {{ cbd.finalPriceUom }} {{ numberWithComma(fixedNumber(cbd.amount, $mathFix, true)) }}
                      </span>
                    </td>
                    <!-- CREATED BY -->
                    <td>
                      <span> {{ cbd.createBy }} </span>
                    </td>
                    <!-- CREATED DATE -->
                    <td>
                      <span> {{ $m(cbd.sysRegDt).format("yyyy-MM-DD") }} </span>
                    </td>
                    <!-- ORDER STATUS MEMO -->
                    <td>
                      <span v-if="!editMode"> {{ cbd.orderStatusMemo }} </span>
                      <v-text-field
                        v-else
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        v-model="cbd.orderStatusMemo" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- right -->
        <div class="price-table">
          <div class="custom-table">
            <table>
              <thead>
                <tr>
                  <th>FINAL PRICE</th>
                  <th>{{ totalPriceInfo.finalPrice }}</th>
                  <th>PORTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>MATERIAL COST</td>
                  <td>
                    {{ totalPriceInfo.mtrlCostPrice }}
                  </td>
                  <td>
                    {{ totalPriceInfo.mtrlCostPortion }}
                  </td>
                </tr>
                <tr>
                  <td>INCIDENTAL COST</td>
                  <td>
                    {{ totalPriceInfo.incdtCostPrice }}
                  </td>
                  <td>
                    {{ totalPriceInfo.incdtCostPortion }}
                  </td>
                </tr>
                <tr>
                  <td>PROFIT</td>
                  <td>
                    {{ totalPriceInfo.profitPrice }}
                  </td>
                  <td>
                    {{ totalPriceInfo.profitPortion }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <table :class="[{ editMode: editMode }, 'merge-tb-wrap tb-cbd-cost']">
      <!-- colgroup :: s -->
      <colgroup>
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <!-- colgroup :: e -->

      <!-- sub3 title - materialCost :: s -->
      <tbody>
        <tr>
          <td scope="col" colspan="100">
            <div class="merge-div title">
              <h3 class="sub3-title">MATERIALS COST</h3>
              <v-spacer />
              <v-btn v-if="editMode" color="secondary" outlined fab x-small @click="addMtrlCost">
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
      <!-- sub3 title - materialCost :: e -->

      <!-- materialCost table :: s -->
      <template v-for="materialCost in materialsCostList">
        <!-- sub4 title :: s -->
        <tbody :key="`tbody sub-title ${materialCost.mtrlCate}`">
          <tr>
            <td scope="col" colspan="100">
              <div class="merge-div">
                <h4 class="sub4-title">{{ materialCost.text }}</h4>
              </div>
            </td>
          </tr>
        </tbody>
        <!-- sub4 title :: e -->

        <!-- materialCost table head :: s -->
        <tbody class="merge-tb-cell head" :key="`tbody head ${materialCost.mtrlCate}`">
          <tr>
            <th scope="col">USAGE</th>
            <th scope="col" :class="{ essential: editMode }">SUPPLIER</th>
            <th scope="col">MILL#</th>
            <th scope="col">RD#</th>
            <th scope="col" :class="{ essential: editMode }">ITEM NAME</th>

            <template v-if="materialCost.mtrlCate !== 'Accessory'">
              <th scope="col" :class="{ essential: editMode && materialCost.mtrlCate === 'Fabric' }">CW/SIZE</th>
              <th scope="col" :class="{ essential: editMode }">UOM (VENDER)</th>
            </template>
            <template v-else>
              <th scope="col" :class="{ essential: editMode }" colspan="2">UOM (VENDER)</th>
            </template>

            <th scope="col" :class="{ essential: editMode }">NET YY</th>
            <th scope="col" :class="{ essential: editMode }">LOSS</th>
            <th scope="col">GROSS YY</th>
            <th scope="col" :class="{ essential: editMode }">UNIT PRICE</th>
            <th scope="col">AMOUNT</th>
            <th scope="col">PORTION</th>
            <th scope="col">OPTIONAL INFORMATION</th>
            <th scope="col" v-if="editMode"></th>
          </tr>
        </tbody>
        <!-- materialCost table head :: e -->

        <!-- materialCost table body :: s -->
        <draggable
          tag="tbody"
          animation="200"
          ghostClass="ghost"
          class="merge-tb-cell body"
          :key="`tbody body ${materialCost.mtrlCate}`"
          filter="input, .total"
          :preventOnFilter="false"
          :disabled="!editMode"
          v-model="
            self()[
              materialCost.mtrlCate === 'Fabric'
                ? 'materialCostFabric'
                : materialCost.mtrlCate === 'Trim'
                ? 'materialCostTrim'
                : 'materialCostAcc'
            ]
          ">
          <tr
            :class="{ handle: editMode }"
            v-for="(mtrlCost, mtrlIndex) in self()[
              materialCost.mtrlCate === 'Fabric'
                ? 'materialCostFabric'
                : materialCost.mtrlCate === 'Trim'
                ? 'materialCostTrim'
                : 'materialCostAcc'
            ]"
            :key="`row ${mtrlCost.cbdMtrlId} ${mtrlIndex}`">
            <!-- USAGE -->
            <td>
              <span v-if="!editMode"> {{ mtrlCost.useAt }} </span>
              <div v-else class="d-flex align-center">
                <i class="mx-1 fa-solid fa-ellipsis-vertical"></i>
                <v-text-field solo flat dense outlined hide-details background-color="white" v-model="mtrlCost.useAt" />
              </div>
            </td>
            <!-- SUPPLIER -->
            <td>
              <span v-if="!editMode"> {{ mtrlCost.spplrName }} </span>
              <span v-else> {{ mtrlCost.spplrName }} </span>
            </td>
            <!-- MILL# -->
            <td>
              <span> {{ mtrlCost.itemNo }} </span>
              <!-- <span v-if="!editMode"> {{ mtrlCost.itemNo }} </span> -->
              <!-- <v-text-field
                      v-else
                      solo
                      flat
                      dense
                      outlined
                      hide-details
                      background-color="white"
                      v-model="mtrlCost.itemNo" /> -->
            </td>
            <!-- RD# -->
            <td>
              <span> {{ mtrlCost.rdNo }} </span>
              <!-- <span v-if="!editMode"> {{ mtrlCost.rdNo }} </span> -->
              <!-- <v-text-field
                      v-else
                      solo
                      flat
                      dense
                      outlined
                      hide-details
                      background-color="white"
                      v-model="mtrlCost.rdNo" /> -->
            </td>
            <!-- ITEM NAME -->
            <td :class="{ required: !hasValue(mtrlCost.itemName) }">
              <span v-if="!editMode"> {{ mtrlCost.itemName }} </span>
              <span v-else> {{ mtrlCost.itemName }} </span>
            </td>
            <!-- CW -->
            <td
              v-if="materialCost.mtrlCate !== 'Accessory'"
              :class="{ required: !hasValue(mtrlCost.cw) && materialCost.mtrlCate === 'Fabric' }">
              <span v-if="!editMode"> {{ mtrlCost.cw }} </span>
              <v-text-field v-else solo flat dense outlined hide-details background-color="white" v-model="mtrlCost.cw">
                <template v-slot:label>
                  <span v-if="materialCost.mtrlCate === 'Fabric'" class="font-error">Required</span>
                </template>
              </v-text-field>
            </td>
            <!-- UOM (VENDER) -->
            <td
              :class="{ required: !hasValue(mtrlCost.fwCwUom) }"
              :colspan="materialCost.mtrlCate !== 'Accessory' ? 1 : 2">
              <span v-if="!editMode"> {{ mtrlCost.fwCwUom }} </span>
              <v-autocomplete
                v-else
                dense
                solo
                hide-details
                flat
                outlined
                :items="
                  materialCost.mtrlCate === 'Fabric'
                    ? $store.state.code.common.uomFabric
                    : $store.state.code.common.uomOther
                "
                item-value="itemName"
                item-text="itemName"
                v-model="mtrlCost.fwCwUom">
                <template v-slot:label>
                  <span class="font-error">Required</span>
                </template>
                <template v-slot:append>
                  <v-icon> mdi-chevron-down </v-icon>
                </template>
              </v-autocomplete>
            </td>
            <!-- NET YY -->
            <td :class="{ required: !hasValue(mtrlCost.netYy) }">
              <span v-if="!editMode"> {{ numberWithComma(mtrlCost.netYy) }} </span>
              <v-text-field
                v-else
                solo
                flat
                dense
                outlined
                hide-details
                background-color="white"
                type="number"
                v-model.number="mtrlCost.netYy"
                @keyup="setMtrlGross(mtrlCost)"
                @change="setMtrlGross(mtrlCost)">
                <template v-slot:label>
                  <span class="font-error">Required</span>
                </template>
              </v-text-field>
            </td>
            <!-- LOSS -->
            <td :class="{ required: !hasValue(mtrlCost.loss) }">
              <span v-if="!editMode"> {{ mtrlCost.loss }} </span>
              <div v-else class="d-flex align-center">
                <v-text-field
                  solo
                  flat
                  dense
                  outlined
                  hide-details
                  background-color="white"
                  type="number"
                  v-model.number="mtrlCost.loss"
                  @keyup="setMtrlGross(mtrlCost)"
                  @change="setMtrlGross(mtrlCost)">
                  <template v-slot:label>
                    <span class="font-error">Required</span>
                  </template>
                </v-text-field>
                %
              </div>
            </td>
            <!-- GROSS YY -->
            <td>
              <span>{{ numberWithComma(fixedNumber(mtrlCost.grossYy, $mathFix, true)) }} </span>
            </td>
            <!-- UNIT PRICE -->
            <td :class="{ required: !hasValue(mtrlCost.unitPrice) }">
              <span v-if="!editMode"> {{ cbd.finalPriceUom }} {{ numberWithComma(mtrlCost.unitPrice) }} </span>
              <v-text-field
                v-else
                solo
                flat
                dense
                outlined
                hide-details
                background-color="white"
                type="number"
                v-model.number="mtrlCost.unitPrice"
                @keyup="setMtrlAmount(mtrlCost)"
                @change="setMtrlAmount(mtrlCost)">
                <template v-slot:label>
                  <span class="font-error">Required</span>
                </template>
              </v-text-field>
            </td>
            <!-- AMOUNT -->
            <td>
              <span> {{ cbd.finalPriceUom }} {{ numberWithComma(fixedNumber(mtrlCost.amount, $mathFix, true)) }} </span>
            </td>
            <!-- PORTION -->
            <td>
              <!-- <span v-if="materialCost.mtrlCate === 'Fabric'">
                {{ getPortion(computedFabricTotal, parseFloat(mtrlCost.amount)) }}%
              </span>
              <span v-if="materialCost.mtrlCate === 'Trim'">
                {{ getPortion(computedTrimTotal, parseFloat(mtrlCost.amount)) }}%
              </span>
              <span v-if="materialCost.mtrlCate === 'Accessory'">
                {{ getPortion(computedAccessoryTotal, parseFloat(mtrlCost.amount)) }}%
              </span> -->
              <span> {{ getPortion(totalPriceInfo.final, mtrlCost.amount) }}% </span>
            </td>
            <!-- OPTIONAL INFORMATION -->
            <td>
              <v-menu
                :open-on-hover="!editMode"
                v-model="mtrlCost.optionalInformationShow"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text x-small color="blue1" v-bind="attrs" v-on="on" :class="editMode ? 'underline' : ''">
                    OPTION
                  </v-btn>
                </template>

                <optional-information
                  :info="{ ...mtrlCost }"
                  :editMode="editMode"
                  @save="
                    val => {
                      mtrlCost.moq = val.moq;
                      mtrlCost.mcq = val.mcq;
                      mtrlCost.leadtime = val.leadtime;
                      mtrlCost.optionalInformationShow = false;
                    }
                  "
                  @cancel="() => (mtrlCost.optionalInformationShow = false)" />
              </v-menu>
            </td>
            <!-- minus -->
            <td v-if="editMode" class="d-flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    outlined
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="copyMaterial(mtrlCost)">
                    <i class="fa-solid fa-clone"></i>
                  </v-btn>
                </template>
                <span>Item copy</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    outlined
                    fab
                    x-small
                    @click="deleteMtrlCost(mtrlCost)"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon> mdi-minus </v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
          <!-- Total -->
          <tr class="total">
            <td :colspan="11" class="text-end">TOTAL OF {{ materialCost.text }} AMOUNT :</td>
            <!-- total amount -->
            <td>
              {{ cbd.finalPriceUom }}
              <span v-if="materialCost.mtrlCate === 'Fabric'"> {{ numberWithComma(computedFabricTotal) }} </span>
              <span v-if="materialCost.mtrlCate === 'Trim'"> {{ numberWithComma(computedTrimTotal) }} </span>
              <span v-if="materialCost.mtrlCate === 'Accessory'">
                {{ numberWithComma(computedAccessoryTotal) }}
              </span>
            </td>
            <!-- total portion -->
            <td>
              <span v-if="materialCost.mtrlCate === 'Fabric'">
                <!-- {{ getPortion(computedFabricTotal + computedTrimTotal + computedAccessoryTotal, computedFabricTotal) }}% -->
                {{ getPortion(totalPriceInfo.final, computedFabricTotal) }}%
              </span>
              <span v-if="materialCost.mtrlCate === 'Trim'">
                {{ getPortion(totalPriceInfo.final, computedTrimTotal) }}%
              </span>
              <span v-if="materialCost.mtrlCate === 'Accessory'">
                {{ getPortion(totalPriceInfo.final, computedAccessoryTotal) }}%
              </span>
            </td>
            <td :colspan="editMode ? 2 : 1"></td>
          </tr>
        </draggable>
        <!-- materialCost table body :: e -->
      </template>
      <!-- materialCost table :: e -->

      <!-- sub3 title - incidentalCost :: s -->
      <tbody>
        <tr>
          <td scope="col" colspan="100">
            <div class="merge-div title">
              <h3 class="sub3-title">INCIDENTAL COST</h3>
            </div>
          </td>
        </tr>
      </tbody>
      <!-- sub3 title - incidentalCost :: e -->

      <!-- incidentalCost table :: s -->
      <template v-for="incidentalCost in incidentalCostList">
        <!-- sub4 title :: s -->
        <tbody :key="`tbody title ${incidentalCost.costCate}`">
          <tr>
            <td scope="col" colspan="100">
              <div class="merge-div">
                <h4 class="sub4-title">{{ incidentalCost.text }}</h4>
                <v-spacer />
                <v-btn
                  v-if="editMode"
                  color="secondary"
                  outlined
                  fab
                  x-small
                  @click="addIncdtCost(incidentalCost.costCate)">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
        <!-- sub4 title :: e -->

        <!-- incidentalCost table head :: s -->
        <tbody class="merge-tb-cell head" :key="`tbody head ${incidentalCost.costCate}`">
          <tr>
            <th scope="col">USAGE</th>
            <th scope="col" colspan="2">{{ incidentalCost.text }} TITLE</th>
            <th scope="col" :class="{ essential: editMode }" colspan="2">TYPE</th>
            <th scope="col" colspan="4">MEMO</th>
            <th scope="col" :class="{ essential: editMode }">QTY</th>
            <th scope="col" :class="{ essential: editMode }">UNIT PRICE</th>
            <th scope="col">AMOUNT</th>
            <th scope="col" colspan="2">PORTION</th>
            <th scope="col" v-if="editMode"></th>
          </tr>
        </tbody>
        <!-- incidentalCost table head :: e -->

        <!-- incidentalCost table body :: s -->
        <draggable
          tag="tbody"
          animation="200"
          ghostClass="ghost"
          class="merge-tb-cell body"
          :key="`tbody body ${incidentalCost.costCate}`"
          filter="input, .total"
          :preventOnFilter="false"
          :disabled="!editMode"
          v-model="self()[incidentalCost.costCate === 'D' ? 'incidentalCostDirect' : 'incidentalCostIndirect']">
          <tr
            :class="{ handle: editMode }"
            v-for="(incdtCost, incdtIndex) in self()[
              incidentalCost.costCate === 'D' ? 'incidentalCostDirect' : 'incidentalCostIndirect'
            ]"
            :key="`row ${incdtCost.cbdIncdtId} ${incdtIndex}`">
            <!-- USAGE -->
            <td>
              <span v-if="!editMode">{{ incdtCost.useAt }}</span>
              <div v-else class="d-flex align-center">
                <i class="mx-1 fa-solid fa-ellipsis-vertical"></i>
                <v-text-field
                  solo
                  flat
                  dense
                  outlined
                  hide-details
                  background-color="white"
                  v-model="incdtCost.useAt" />
              </div>
            </td>
            <!-- COST TITLE -->
            <td colspan="2">
              <span v-if="!editMode">{{ incdtCost.costTitle }}</span>
              <v-text-field
                v-else
                solo
                flat
                dense
                outlined
                hide-details
                background-color="white"
                v-model="incdtCost.costTitle">
              </v-text-field>
            </td>
            <!-- TYPE -->
            <td :class="{ required: !hasValue(incdtCost.costType) }" colspan="2">
              <span v-if="!editMode">{{ incdtCost.costType }}</span>
              <v-autocomplete
                v-else
                dense
                solo
                hide-details
                flat
                outlined
                :items="
                  incidentalCost.costCate === 'D'
                    ? $store.state.company.company[$store.getters.getCmpyUUID].directCostType
                    : $store.state.company.company[$store.getters.getCmpyUUID].indirectCostType
                "
                item-text="itemName"
                item-value="itemName"
                v-model="incdtCost.costType">
                <template v-slot:label>
                  <span class="font-error">Required</span>
                </template>
                <template v-slot:append>
                  <v-icon> mdi-chevron-down </v-icon>
                </template>
              </v-autocomplete>
            </td>
            <!-- MEMO -->
            <td colspan="4">
              <span v-if="!editMode">{{ incdtCost.costMemo }}</span>
              <v-text-field
                v-else
                solo
                flat
                dense
                outlined
                hide-details
                background-color="white"
                v-model="incdtCost.costMemo">
              </v-text-field>
            </td>
            <!-- QTY -->
            <td :class="{ required: !hasValue(incdtCost.qty) }">
              <span v-if="!editMode">{{ numberWithComma(incdtCost.qty) }}</span>
              <v-text-field
                v-else
                solo
                flat
                dense
                outlined
                hide-details
                background-color="white"
                type="number"
                v-model.number="incdtCost.qty"
                @keyup="setIncdtAmount(incdtCost)"
                @change="setIncdtAmount(incdtCost)">
                <template v-slot:label>
                  <span class="font-error">Required</span>
                </template>
              </v-text-field>
            </td>
            <!-- UNIT PRICE -->
            <td :class="{ required: !hasValue(incdtCost.unitPrice) || !hasValue(incdtCost.unitPriceUom) }">
              <span v-if="!editMode && incdtCost.unitPriceUom === '%'">
                {{ numberWithComma(incdtCost.unitPrice) }} %
              </span>
              <span v-else-if="!editMode"> {{ cbd.finalPriceUom }} {{ numberWithComma(incdtCost.unitPrice) }} </span>

              <div v-else class="d-flex">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="incidentalUomList"
                  v-model="incdtCost.unitPriceUom"
                  @change="setIncdtAmount(incdtCost)">
                  <template v-slot:label>
                    <span class="font-error">Required</span>
                  </template>
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>

                <v-text-field
                  solo
                  flat
                  dense
                  outlined
                  hide-details
                  background-color="white"
                  type="number"
                  v-model.number="incdtCost.unitPrice"
                  @keyup="setIncdtAmount(incdtCost)"
                  @change="setIncdtAmount(incdtCost)">
                  <template v-slot:label>
                    <span class="font-error">Required</span>
                  </template>
                </v-text-field>
              </div>
            </td>
            <!-- AMOUNT -->
            <td>
              <span>
                {{ cbd.finalPriceUom }} {{ numberWithComma(fixedNumber(incdtCost.amount, $mathFix, true)) }}
              </span>
            </td>
            <!-- PORTION -->
            <td colspan="2">
              <!-- <span v-if="incidentalCost.costCate === 'D'">
                {{ getPortion(computedDirectTotal, incdtCost.amount) }}%
              </span>
              <span v-else> {{ getPortion(computedIndirectTotal, incdtCost.amount) }}% </span> -->
              {{ getPortion(totalPriceInfo.final, incdtCost.amount) }}%
            </td>
            <!-- minus -->
            <td v-if="editMode">
              <v-btn color="secondary" outlined fab x-small @click="deleteIncdtCost(incdtCost)">
                <v-icon> mdi-minus </v-icon>
              </v-btn>
            </td>
          </tr>
          <!-- total -->
          <tr class="total">
            <td colspan="11" class="text-end">{{ incidentalCost.text }} AMOUNT IN TOTAL :</td>
            <!-- total amount -->
            <td>
              <span v-if="incidentalCost.costCate === 'D'">
                {{ cbd.finalPriceUom }} {{ numberWithComma(computedDirectTotal) }}
              </span>
              <span v-else>{{ cbd.finalPriceUom }} {{ numberWithComma(computedIndirectTotal) }} </span>
            </td>
            <!-- total portion -->
            <td colspan="2">
              <span v-if="incidentalCost.costCate === 'D'">
                {{ getPortion(totalPriceInfo.final, computedDirectTotal) }}%
              </span>
              <span v-else> {{ getPortion(totalPriceInfo.final, computedIndirectTotal) }}% </span>
            </td>
            <td v-if="editMode"></td>
          </tr>
        </draggable>
        <!-- incidentalCost table body :: e -->
      </template>
      <!-- incidentalCost table :: e -->
    </table>

    <div class="actions d-flex justify-center" v-if="editMode">
      <v-btn :elevation="0" color="secondary" @click="save" :loading="saveLoading">SAVE</v-btn>
      <v-btn :elevation="0" color="secondary" @click="changeEditMode(false)">CANCEL</v-btn>
    </div>

    <select-style-number
      :styleNoList.sync="cbd.cbdStyleNoList"
      :show.sync="selectStyleNumberShow"
      :bomUUID="cbd.cbdBomSelectDto.bomUUID"
      :cmpyUUID="$store.getters.getCmpyUUID"
      @addStyle="style => cbd.cbdStyleNoList.push({ ...style })"
      @deleteStyle="
        style =>
          cbd.cbdStyleNoList.splice(
            cbd.cbdStyleNoList.findIndex(s => s.styleNo === style.styleNo),
            1,
          )
      " />

    <set-supplier-item :show.sync="setSupplierItemShow" :mtrlCostList="[]" @save="saveSupplierItem" />

    <confirm :show.sync="deleteConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="discard">
      Are you trying to discard this CBD? <br />
      The deleted CBD shall not be restored
    </confirm>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import OptionalInformation from "@/components/company/bom/cbd/OptionalInformation.vue";
import SetSupplierItem from "@/components/company/bom/cbd/SetSupplierItem.vue";
import SelectStyleNumber from "@/components/company/bom/cbd/SelectStyleNumber.vue";
import InputDate from "@/components/common/inputs/InputDate.vue";
import InputSwitch from "@/components/common/inputs/InputSwitch.vue";
import Confirm from "@/components/common/cards/Confirm.vue";
import codeHandler from "@/utils/codeHandler";
import { isNumber, hasValue, numberWithComma, fixedNumber, calc, _deepCopy } from "@/utils/util";
import messageHandler from "@/utils/messageHandler";
import draggable from "vuedraggable";

export default {
  components: { OptionalInformation, SetSupplierItem, SelectStyleNumber, InputDate, InputSwitch, Confirm, draggable },
  props: {
    fromSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: false,
      createMode: false, //editMode 하위레벨
      materialsCostList: [
        { text: "FABRIC", mtrlCate: "Fabric" },
        { text: "TRIM", mtrlCate: "Trim" },
        { text: "ACCESSORY", mtrlCate: "Accessory" },
      ],
      incidentalCostList: [
        {
          text: "DIRECT COST",
          costCate: "D",
        },
        {
          text: "INDIRECT COST",
          costCate: "I",
        },
      ],
      backupCBD: {},
      cbd: {
        cbdBomSelectDto: {},
        cbdStyleNoList: [],
        cbdMtrlCostSelectDtoList: [],
        cbdIncdtCostSelectDtoList: [],
      },
      loading: false,
      saveLoading: false,
      setSupplierItemShow: false,
      selectStyleNumberShow: false,
      useFinalPrice: true, //true: useFinalPrice, false: useProfit, undefined: not
      deleteConfirmShow: false,
    };
  },
  methods: {
    getCbdInfo() {
      this.loading = true;
      this.$http
        .get("/cbd", {
          data: {
            cbdUUID: this.$store.state.currentRoute.params.cbdUUID,
            cmpyUUID: this.$store.state.currentRoute.params.cmpyUUID,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            this.cbd = this.settingCBD(res.data.data);
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    settingCBD(data) {
      const cbd = {
        ...data,
        shippingDateOpen: false,
        pcdOpen: false,
      };

      cbd.status = data.status || "OPEN";
      cbd.isClosed = data.status === "CLOSED";

      if (Array.isArray(cbd.cbdMtrlCostSelectDtoList) && cbd.cbdMtrlCostSelectDtoList.length > 0) {
        cbd.cbdMtrlCostSelectDtoList.forEach(c => (c.optionalInformationShow = false));
      }
      return cbd;
    },
    changeEditMode(editMode, notChanged) {
      if (editMode) {
        //수정모드로 진입
        this.backupCBD = _deepCopy(this.cbd);
      } else {
        //읽기모드로 진입
        if (this.createMode && !notChanged) {
          //생성중이었을때
          this.$router.go(-1); //뒤로가기
        } else {
          //수정중이었을때
          if (!notChanged) this.cbd = _deepCopy(this.backupCBD);
          else this.getCbdInfo();
        }
      }

      this.editMode = editMode;
    },
    switchClick(cbd) {
      if (cbd.status !== "CLOSED") {
        // #region cbdInfo
        const cbdUpdateDto = this.createCBDInfo(this.cbd);

        const checkCBD = this.checkCBDInfo(cbdUpdateDto);
        if (checkCBD !== true) return messageHandler(checkCBD);

        const cbdStyleNoList = this.cbd.cbdStyleNoList.filter(s => s.isUse === "Y").map(s => s.styleNo);
        const checkStyleList = this.checkStyleList(cbdStyleNoList);
        if (checkStyleList !== true) return messageHandler(checkStyleList);
        //#endregion

        if (
          !Array.isArray(this.cbd.cbdMtrlCostSelectDtoList) ||
          !Array.isArray(this.cbd.cbdIncdtCostSelectDtoList) ||
          this.cbd.cbdMtrlCostSelectDtoList.length + this.cbd.cbdIncdtCostSelectDtoList.length < 1
        )
          return this.$toast.warning("There must be at least one item");

        // #region cbdMtrlCost
        let breakReason = "";
        for (let mtrlIndex = 0; mtrlIndex < this.cbd.cbdMtrlCostSelectDtoList.length; mtrlIndex++) {
          const m = this.cbd.cbdMtrlCostSelectDtoList[mtrlIndex];

          const mtrl = this.createMtrlCostInfo(m);

          const checkMtrl = this.checkMtrlCostInfo(mtrl);
          if (checkMtrl !== true) {
            breakReason = checkMtrl;
            break;
          }
        }

        if (breakReason !== "") return messageHandler(breakReason);
        // #endregion

        // #region cbdIncdtCost
        breakReason = "";
        for (let mtrlIndex = 0; mtrlIndex < this.cbd.cbdIncdtCostSelectDtoList.length; mtrlIndex++) {
          const i = this.cbd.cbdIncdtCostSelectDtoList[mtrlIndex];

          const incdt = this.createIncdtCostInfo(i);

          const checkIncdt = this.checkIncdtCostInfo(incdt);
          if (checkIncdt !== true) {
            breakReason = checkIncdt;
            break;
          }
        }

        if (breakReason !== "") return messageHandler(breakReason);
        // #endregion
      }

      const status = cbd.status === "CLOSED" ? "OPEN" : "CLOSED";
      this.$http
        .put("/cbd/status", {
          bomUUID: this.cbd.bomUUID,
          cmpyUUID: this.$store.getters.getCmpyUUID,
          cbdUUID: this.cbd.cbdUUID,
          status,
        })
        .then(res => {
          if (res.data.status === 200) {
            cbd.status = status;
            cbd.isClosed = !cbd.isClosed;
          } else codeHandler(res.data);
        });
    },
    setAmount() {
      const amount = this.calc(parseFloat(this.cbd.orderQty) * parseFloat(this.cbd.finalPrice));
      this.cbd.amount = isNumber(amount) ? amount : 0;
    },
    setMtrlGross(mtrlCost) {
      if (!mtrlCost) return;
      const grossYy = this.calc(
        parseFloat(mtrlCost.netYy) + parseFloat(mtrlCost.netYy) * this.calc(parseFloat(mtrlCost.loss) * 0.01),
      );
      mtrlCost.grossYy = isNumber(grossYy) ? grossYy : 0;
      this.setMtrlAmount(mtrlCost);
    },
    setMtrlAmount(mtrlCost) {
      if (!mtrlCost) return;
      const amount = this.calc(parseFloat(mtrlCost.unitPrice) * parseFloat(mtrlCost.grossYy));
      mtrlCost.amount = isNumber(amount) ? amount : 0;
    },
    addMtrlCost() {
      this.setSupplierItemShow = true;
    },
    saveSupplierItem(items) {
      if (!Array.isArray(items)) return;
      items.forEach(i => {
        this.cbd.cbdMtrlCostSelectDtoList.push({
          mtrlUUID: i.mtrlUUID,
          mtrlCate: i.mtrlCate,
          mtrlType: i.mtrlType,
          mtrlCompRate: i.itemDetail,
          useAt: "",
          spplrUUID: i.spplrUUID,
          spplrName: i.spplrName,
          itemNo: i.itemNo || "",
          rdNo: i.rdNo || "",
          itemName: i.itemName,
          cw: "",
          fwCwUom: "",
          moq: 0,
          mcq: 0,
          leadtime: 0,
          netYy: 0,
          loss: 0,
          grossYy: 0,
          unitPrice: 0,
          amount: 0,
          optionalInformationShow: false,
        });
      });
    },
    deleteMtrlCost(mtrlCost) {
      this.cbd.cbdMtrlCostSelectDtoList.splice(
        this.cbd.cbdMtrlCostSelectDtoList.findIndex(c => c === mtrlCost),
        1,
      );
    },
    setIncdtAmount(incdtCost) {
      if (!incdtCost) return;
      if (!hasValue(incdtCost.unitPriceUom)) return 0;

      if (incdtCost.unitPriceUom === "%") {
        const amount = this.calc(this.calc(parseFloat(this.cbd.finalPrice) * parseFloat(incdtCost.unitPrice)) / 100);
        incdtCost.amount = isNumber(amount) ? amount : 0;
      } else {
        const amount = this.calc(parseFloat(incdtCost.qty) * parseFloat(incdtCost.unitPrice));
        incdtCost.amount = isNumber(amount) ? amount : 0;
      }
    },
    addIncdtCost(costCate) {
      this.cbd.cbdIncdtCostSelectDtoList.push({
        costCate: costCate,
        useAt: "",
        costTitle: "",
        costType: "",
        costMemo: "",
        qty: 0,
        unitPrice: 0,
        unitPriceUom: "",
        amount: 0,
        isUse: "",
      });
    },
    deleteIncdtCost(incdtCost) {
      this.cbd.cbdIncdtCostSelectDtoList.splice(
        this.cbd.cbdIncdtCostSelectDtoList.findIndex(c => c === incdtCost),
        1,
      );
    },
    calculateAmount(list, targetColumn, targetValue) {
      let amount = 0;
      list
        .filter(c => c[targetColumn] === targetValue)
        .forEach(c => {
          amount = calc(parseFloat(amount) + parseFloat(c.amount));
        });
      return isNumber(amount) ? this.fixedNumber(amount, this.$mathFix) : 0;
    },
    save() {
      const cbdUpdateDto = this.createCBDInfo(this.cbd);

      const cbdStyleNoList = this.cbd.cbdStyleNoList.filter(s => s.isUse === "Y").map(s => s.styleNo);

      const cbdMtrlCostInsertDtoList = [];
      for (let mtrlIndex = 0; mtrlIndex < this.cbd.cbdMtrlCostSelectDtoList.length; mtrlIndex++) {
        const c = this.createMtrlCostInfo(this.cbd.cbdMtrlCostSelectDtoList[mtrlIndex]);
        c.priority = mtrlIndex;
        cbdMtrlCostInsertDtoList.push(c);
      }
      const cbdIncdtCostInsertDtoList = [];
      for (let mtrlIndex = 0; mtrlIndex < this.cbd.cbdIncdtCostSelectDtoList.length; mtrlIndex++) {
        const c = this.createIncdtCostInfo(this.cbd.cbdIncdtCostSelectDtoList[mtrlIndex]);
        c.priority = mtrlIndex;
        cbdIncdtCostInsertDtoList.push(c);
      }

      this.saveLoading = true;

      if (this.createMode) {
        // createMode
        cbdUpdateDto.bomUUID = this.cbd.cbdBomSelectDto.bomUUID;
        delete cbdUpdateDto.cbdUUID;

        this.$http
          .post("/cbd", {
            cbdInsertDto: cbdUpdateDto,
            cbdStyleNoList,
            cbdMtrlCostInsertDtoList,
            cbdIncdtCostInsertDtoList,
          })
          .then(res => {
            if (res.data.status === 200) {
              this.$toast.success("Successfully saved");
              if (res.data.data.cbdUUID) this.$router.push({ name: "CBD", params: { cbdUUID: res.data.data.cbdUUID } });
              else this.$router.go(-1);
            } else codeHandler(res.data);
          })
          .finally(() => (this.saveLoading = false));
      } else {
        // editMode
        this.$http
          .put("/cbd", { cbdUpdateDto, cbdStyleNoList, cbdMtrlCostInsertDtoList, cbdIncdtCostInsertDtoList })
          .then(res => {
            if (res.data.status === 200) {
              this.$toast.success("Successfully saved");
              this.changeEditMode(false, true);
            } else codeHandler(res.data);
          })
          .finally(() => (this.saveLoading = false));
      }
    },
    checkCBDInfo(cbd) {
      if (
        ![cbd.coo, cbd.destination, cbd.cbdTitle, cbd.orderQty, cbd.finalPriceUom, cbd.finalPrice, cbd.profit].every(
          hasValue,
        )
      )
        return "required";
      if (![cbd.exchangeRate, cbd.orderQty, cbd.finalPrice, cbd.profit].every(val => isNumber(val, true)))
        return "number";
      return true;
    },
    checkStyleList(cbdStyleNoList) {
      if (cbdStyleNoList.length < 1) return "styleRequired";
      return true;
    },
    checkMtrlCostInfo(mtrl) {
      if (![mtrl.mtrlUUID, mtrl.spplrUUID].every(hasValue)) {
        return "error";
      }

      if (mtrl.mtrlCate === "Fabric" && !hasValue(mtrl.cw)) return "required";

      if (![mtrl.itemName, mtrl.fwCwUom, mtrl.netYy, mtrl.loss, mtrl.unitPrice].every(hasValue)) {
        return "required";
      }
      if (![mtrl.netYy, mtrl.loss, mtrl.unitPrice].every(val => isNumber(val, true))) {
        return "number";
      }

      return true;
    },
    checkIncdtCostInfo(incdt) {
      if (![incdt.costType, incdt.qty, incdt.unitPriceUom, incdt.unitPrice].every(hasValue)) {
        return "required";
      }
      if (![incdt.qty, incdt.unitPrice].every(val => isNumber(val, true))) {
        return "number";
      }

      return true;
    },
    createCBDInfo(val) {
      if (!hasValue(val)) val = {};
      return {
        bomUUID: hasValue(val.bomUUID) ? val.bomUUID : "",
        cmpyUUID: this.$store.getters.getCmpyUUID,
        cbdUUID: hasValue(val.cbdUUID) ? val.cbdUUID : "",
        department: hasValue(val.department) ? val.department : "",
        orderStatusMemo: hasValue(val.orderStatusMemo) ? val.orderStatusMemo : "",
        coo: hasValue(val.coo) ? val.coo : "",
        pcd: hasValue(val.pcd) ? val.pcd : "",
        shippingDate: hasValue(val.shippingDate) ? val.shippingDate : "",
        destination: hasValue(val.destination) ? val.destination : "",
        exchangeRate: val.exchangeRate,
        cbdTitle: hasValue(val.cbdTitle) ? val.cbdTitle : "",
        orderQty: val.orderQty,
        finalPriceUom: hasValue(val.finalPriceUom) ? val.finalPriceUom : "",
        finalPrice: val.finalPrice,
        profit: val.profit,
        amount: hasValue(val.amount) ? val.amount : "",
      };
    },
    createMtrlCostInfo(val) {
      if (!hasValue(val)) val = {};
      return {
        mtrlUUID: hasValue(val.mtrlUUID) ? val.mtrlUUID : "",
        mtrlCate: hasValue(val.mtrlCate) ? val.mtrlCate : "",
        mtrlType: hasValue(val.mtrlType) ? val.mtrlType : "",
        mtrlCompRate: hasValue(val.mtrlCompRate) ? val.mtrlCompRate : "",
        useAt: hasValue(val.useAt) ? val.useAt : "",
        spplrUUID: hasValue(val.spplrUUID) ? val.spplrUUID : "",
        spplrName: hasValue(val.spplrName) ? val.spplrName : "",
        itemNo: hasValue(val.itemNo) ? val.itemNo : "",
        rdNo: hasValue(val.rdNo) ? val.rdNo : "",
        itemName: hasValue(val.itemName) ? val.itemName : "",
        cw: val.cw,
        fwCwUom: val.fwCwUom,
        netYy: val.netYy,
        loss: val.loss,
        grossYy: hasValue(val.grossYy) ? val.grossYy : "",
        unitPrice: val.unitPrice,
        amount: hasValue(val.amount) ? val.amount : "",
        moq: hasValue(val.moq) ? val.moq : "",
        mcq: hasValue(val.mcq) ? val.mcq : "",
        leadtime: hasValue(val.leadtime) ? val.leadtime : "",
      };
    },
    createIncdtCostInfo(val) {
      if (!hasValue(val)) val = {};
      return {
        costCate: hasValue(val.costCate) ? val.costCate : "",
        useAt: hasValue(val.useAt) ? val.useAt : "",
        costTitle: hasValue(val.costTitle) ? val.costTitle : "",
        costType: hasValue(val.costType) ? val.costType : "",
        costMemo: hasValue(val.costMemo) ? val.costMemo : "",
        qty: val.qty,
        unitPriceUom: hasValue(val.unitPriceUom) ? val.unitPriceUom : "",
        unitPrice: val.unitPrice,
        amount: hasValue(val.amount) ? val.amount : "",
      };
    },
    getPortion(total, val) {
      const portion = this.calc(this.calc(parseFloat(val) / parseFloat(total)) * 100);
      return isNumber(portion) ? fixedNumber(portion, this.$mathFix, true) : 0;
    },
    setFinalPrice(finalPrice) {
      this.cbd.finalPrice = finalPrice;
      this.changeFinalPrice(finalPrice);
    },
    changeFinalPrice(finalPrice) {
      this.setAmount();

      if (!isNumber(finalPrice)) return;

      for (let index = 0; index < this.cbd.cbdIncdtCostSelectDtoList.length; index++) {
        const incdtCost = this.cbd.cbdIncdtCostSelectDtoList[index];
        if (incdtCost.unitPriceUom === "%") {
          const amount = this.calc(this.calc(parseFloat(finalPrice) * parseFloat(incdtCost.unitPrice)) / 100);
          incdtCost.amount = isNumber(amount) ? amount : 0;
        }
      }
    },
    setProfit(profit) {
      this.cbd.profit = profit;
    },
    discardClicked() {
      this.deleteConfirmShow = true;
    },
    discard() {
      this.loading = true;
      this.$http
        .patch("/cbd", {
          cmpyUUID: this.$store.getters.getCmpyUUID,
          cbdUUID: this.$store.state.currentRoute.params.cbdUUID,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Discarded successfully.");
            this.$router.push({ name: "BOM" });
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    goBack() {
      if (
        this.fromSearch === true &&
        this.$store.state.user.bomSearchOption.targetUUID === this.$store.state.currentRoute.params.cbdUUID
      )
        this.$router.push({ name: "BOM", query: { loadSearchOption: true } });
      else {
        this.$router.push({ name: "BOM", query: { bomUUID: this.cbd.bomUUID } });
      }
    },
    self() {
      return this;
    },
    copyMaterial(mtrlCost) {
      if (mtrlCost) {
        const index = this.cbd.cbdMtrlCostSelectDtoList.findIndex(i => i === mtrlCost);
        if (index !== -1) {
          const newCost = _deepCopy(mtrlCost);
          newCost.cbdMtrlId = undefined;
          this.cbd.cbdMtrlCostSelectDtoList.splice(index + 1, 0, newCost);
        }
      }
    },
    // getDispositionFileName(response) {
    //   if (!response) return false;

    //   let fileName = "";

    //   const disposition = response.headers["content-disposition"];
    //   if (disposition && disposition.indexOf("attachment") !== -1) {
    //     const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    //     const matches = filenameRegex.exec(disposition);
    //     if (matches != null && matches[1]) {
    //       fileName = matches[1].replace(/['"]/g, "");
    //     }
    //   }

    //   return fileName;
    // },
    getFileName() {
      // CBD_브랜드명_Design#_CBD Title
      const { offerBrand, designNo } = this.cbd.cbdBomSelectDto;
      return `CBD_${offerBrand}_${designNo}_${this.cbd.cbdTitle}`.replace(/[\\/:*?"<>|.]/g, "").concat(".xlsx");
    },
    downloadExcel() {
      this.$http({
        url: `cbd/excel?cmpyUUID=${this.$store.getters.getCmpyUUID}&cbdUUID=${this.$store.state.currentRoute.params.cbdUUID}`, //your url
        method: "GET",
        responseType: "blob",
      }).then(response => {
        const contentType = response.headers["content-type"];

        if (contentType === "application/json") {
          new Blob([response.data], { type: response.headers["content-type"] })
            .text()
            .then(res => {
              try {
                const responseJSON = JSON.parse(res);
                if (responseJSON.status !== "200") codeHandler(responseJSON);
              } catch (err) {
                messageHandler("error");
                console.error("excel download, content type is json. blob to text is success, json parse error");
                console.error(res);
                console.error(err);
              }
            })
            .catch(err => {
              messageHandler("error");
              console.error("excel download, content type is json, blob to text is failed");
              console.error(err);
            });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.getFileName());
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    hasValue,
    isNumber,
    numberWithComma,
    fixedNumber,
    calc,
    _deepCopy,
  },
  computed: {
    styleNoText() {
      let text = "";
      const filterdList = this.cbd.cbdStyleNoList.filter(s => s.isUse === "Y");

      filterdList.forEach((s, i) => {
        text += s.styleNo;
        if (i !== filterdList.length - 1) text += ", ";
      });
      return text;
    },
    computedTotal() {
      let total = 0;
      this.cbd.cbdMtrlCostSelectDtoList.forEach(c => {
        total = this.calc(parseFloat(total) + parseFloat(c.amount));
      });
      this.cbd.cbdIncdtCostSelectDtoList.forEach(c => {
        total = this.calc(parseFloat(total) + parseFloat(c.amount));
      });
      return isNumber(total) ? total : 0;
    },
    computedFabricTotal() {
      return this.calculateAmount(this.cbd.cbdMtrlCostSelectDtoList, "mtrlCate", "Fabric");
    },
    computedTrimTotal() {
      return this.calculateAmount(this.cbd.cbdMtrlCostSelectDtoList, "mtrlCate", "Trim");
    },
    computedAccessoryTotal() {
      return this.calculateAmount(this.cbd.cbdMtrlCostSelectDtoList, "mtrlCate", "Accessory");
    },
    computedIndirectTotal() {
      return this.calculateAmount(this.cbd.cbdIncdtCostSelectDtoList, "costCate", "I");
    },
    computedDirectTotal() {
      return this.calculateAmount(this.cbd.cbdIncdtCostSelectDtoList, "costCate", "D");
    },
    incidentalUomList() {
      return [
        { text: this.cbd.finalPriceUom, value: "*" },
        { text: "%", value: "%" },
      ];
    },
    totalPriceInfo() {
      const finalPriceUom = this.cbd.finalPriceUom;

      const mtrlCost = this.computedFabricTotal + this.computedTrimTotal + this.computedAccessoryTotal;
      const incdtCost = this.computedIndirectTotal + this.computedDirectTotal;
      const totalPrice = this.useFinalPrice
        ? this.cbd.finalPrice || 0
        : calc(((mtrlCost + incdtCost) * 100) / (100 - parseFloat(this.cbd.profit))) || 0;
      const profit = calc(totalPrice - (mtrlCost + incdtCost));
      const profitPer = calc((profit / totalPrice) * 100);

      if (this.useFinalPrice === false && this.cbd.totalPrice !== totalPrice) this.setFinalPrice(totalPrice);
      if (this.useFinalPrice === true && this.cbd.profit !== profitPer) this.setProfit(profitPer);

      return {
        final: totalPrice,
        finalPrice: finalPriceUom + " " + numberWithComma(totalPrice),
        mtrlCost,
        mtrlCostPrice: finalPriceUom + " " + numberWithComma(calc(mtrlCost)),
        mtrlCostPortion: fixedNumber(calc((mtrlCost / totalPrice) * 100), this.$mathFix, true) + "%",
        incdtCost,
        incdtCostPrice: finalPriceUom + " " + numberWithComma(calc(incdtCost)),
        incdtCostPortion: fixedNumber(calc((incdtCost / totalPrice) * 100), this.$mathFix, true) + "%",
        profit,
        profitPrice: finalPriceUom + " " + numberWithComma(fixedNumber(calc(profit), this.$mathFix, true)),
        profitPortion: fixedNumber(profitPer, this.$mathFix, true) + "%",
      };
    },
    materialCostFabric: {
      get() {
        return this.cbd.cbdMtrlCostSelectDtoList.filter(p => p.mtrlCate === "Fabric");
      },
      set(value) {
        this.cbd.cbdMtrlCostSelectDtoList = value.concat(this.materialCostTrim).concat(this.materialCostAcc);
      },
    },
    materialCostTrim: {
      get() {
        return this.cbd.cbdMtrlCostSelectDtoList.filter(p => p.mtrlCate === "Trim");
      },
      set(value) {
        this.cbd.cbdMtrlCostSelectDtoList = this.materialCostFabric.concat(value).concat(this.materialCostAcc);
      },
    },
    materialCostAcc: {
      get() {
        return this.cbd.cbdMtrlCostSelectDtoList.filter(p => p.mtrlCate === "Accessory");
      },
      set(value) {
        this.cbd.cbdMtrlCostSelectDtoList = this.materialCostFabric.concat(this.materialCostTrim).concat(value);
      },
    },
    incidentalCostDirect: {
      get() {
        return this.cbd.cbdIncdtCostSelectDtoList.filter(p => p.costCate === "D");
      },
      set(value) {
        this.cbd.cbdIncdtCostSelectDtoList = value.concat(this.incidentalCostIndirect);
      },
    },
    incidentalCostIndirect: {
      get() {
        return this.cbd.cbdIncdtCostSelectDtoList.filter(p => p.costCate === "I");
      },
      set(value) {
        this.cbd.cbdIncdtCostSelectDtoList = this.incidentalCostDirect.concat(value);
      },
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.editMode = false;
        this.createMode = false;
        this.getCbdInfo();
      },
    );

    if (this.$store.state.currentRoute.params.cbdUUID === "create") {
      const bomInfo = this.$store.state.currentRoute.params.bomInfo;
      if (!hasValue(bomInfo)) {
        this.$toast.error("It's an abnormal approach.");
        return this.$router.go(-1);
      }

      const cbd = this.createCBDInfo();
      cbd.shippingDateOpen = false;
      cbd.pcdOpen = false;
      cbd.status = "OPEN";
      cbd.isClosed = false;
      cbd.finalPriceUom = "$";
      cbd.createBy = this.$store.state.user.name;
      cbd.cbdBomSelectDto = bomInfo;
      cbd.cbdStyleNoList = bomInfo.bomStyleNoList.map(s => ({ isUse: "N", styleNo: s.styleNo }));
      cbd.cbdMtrlCostSelectDtoList = [];
      cbd.cbdIncdtCostSelectDtoList = [];

      [
        { costCate: "D", costType: "Sewing Charge", unitPriceUom: "*" },
        { costCate: "D", costType: "Packing Charge", unitPriceUom: "*" },
        { costCate: "D", costType: "Handling Charge", unitPriceUom: "*" },
        { costCate: "D", costType: "Embroidery", unitPriceUom: "*" },
        { costCate: "D", costType: "Washing", unitPriceUom: "*" },
        { costCate: "D", costType: "Material Freight (Normal)", unitPriceUom: "%" },
        { costCate: "I", costType: "Commission", unitPriceUom: "%" },
        { costCate: "I", costType: "Insurance", unitPriceUom: "%" },
        { costCate: "I", costType: "Material Interest", unitPriceUom: "%" },
        { costCate: "I", costType: "Develop Cost", unitPriceUom: "%" },
      ].forEach(incdt => {
        const items =
          incdt.costCate === "D"
            ? this.$store.state.company.company[this.$store.getters.getCmpyUUID].directCostType
            : this.$store.state.company.company[this.$store.getters.getCmpyUUID].indirectCostType;

        if (items.find(i => i.itemName === incdt.costType)) {
          cbd.cbdIncdtCostSelectDtoList.push({
            costCate: incdt.costCate,
            costType: incdt.costType,
            qty: 1,
            unitPrice: 0,
            unitPriceUom: incdt.unitPriceUom,

            useAt: "",
            costTitle: "",
            costMemo: "",
            amount: 0,
            isUse: "",
          });
        }
      });

      this.cbd = cbd;
      this.editMode = true;
      this.createMode = true;
    } else this.getCbdInfo();
  },
};
</script>

<style></style>
