<template>
  <v-card class="pa-5 company optinal-information" min-width="400px">
    <h2>OPTIONAL INFORMATION</h2>

    <div class="mt-3 content">
      <div class="custom-table">
        <table>
          <thead>
            <tr>
              <th>MOQ</th>
              <th>MCQ</th>
              <th>LEAD TIME</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span v-if="!editMode"> {{ optionalInfo.moq }} </span>
                <v-text-field
                  v-else
                  solo
                  flat
                  dense
                  outlined
                  hide-details
                  background-color="white"
                  type="number"
                  v-model.number="optionalInfo.moq" />
              </td>
              <td>
                <span v-if="!editMode"> {{ optionalInfo.mcq }} </span>
                <v-text-field
                  v-else
                  solo
                  flat
                  dense
                  outlined
                  hide-details
                  background-color="white"
                  type="number"
                  v-model.number="optionalInfo.mcq" />
              </td>
              <td>
                <span v-if="!editMode"> {{ optionalInfo.leadtime }} </span>
                <v-text-field
                  v-else
                  solo
                  flat
                  dense
                  outlined
                  hide-details
                  background-color="white"
                  type="number"
                  v-model.number="optionalInfo.leadtime" />
                days
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="actions d-flex justify-center mt-4" v-if="editMode">
        <v-btn :elevation="0" color="secondary" @click="save" small> OK </v-btn>
        <v-btn :elevation="0" color="secondary" small @click="$emit('cancel')" class="ml-2"> CANCEL </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { isNumber } from "@/utils/util";
export default {
  props: ["info", "editMode"],
  data() {
    return {
      optionalInfo: {
        moq: 0,
        mcq: 0,
        leadtime: 0,
      },
    };
  },
  methods: {
    save() {
      if (![this.optionalInfo.moq, this.optionalInfo.mcq, this.optionalInfo.leadtime].every(isNumber))
        return this.$toast.warning("Please only be entered with numbers");
      else this.$emit("save", this.optionalInfo);
    },
  },
  watch: {
    info(newVal) {
      this.optionalInfo.moq = newVal.moq;
      this.optionalInfo.mcq = newVal.mcq;
      this.optionalInfo.leadtime = newVal.leadtime;
    },
  },
  created() {
    this.optionalInfo.moq = this.info.moq;
    this.optionalInfo.mcq = this.info.mcq;
    this.optionalInfo.leadtime = this.info.leadtime;
  },
};
</script>

<style></style>
